/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

	
//	$('.menu-item-has-children a').on('mouseover', function(){
//		$(this).parent('.menu-item-has-children').toggleClass('show');
//	});
//	$('button.navbar-toggler').on('click', function(){
//		if($(this).hasClass('show')){
//			console.log('has');
//			$('#nav-pop').show();
//		}else{
//			$('#nav-pop').hide();
//		}
//	});
	
	$('.back-1 > .text').on('click', function(){
		var popimage = $('.pop-1 .desktop-only img');
		if($(window).width() <= 768){
			popimage.attr('src', popimage.data('mobile'));
		}else{
			popimage.attr('src', popimage.data('desktop'));
		}
		$('.pop-1').show();
		$('body,html').addClass('bodyscroll');
	});
		
	$('.back-2 > .text').on('click', function(){
		var popimage = $('.pop-2 .desktop-only img');
		if($(window).width() <= 768){
			popimage.attr('src', popimage.data('mobile'));
		}else{
			popimage.attr('src', popimage.data('desktop'));
		}
		$('.pop-2').show();
		$('body,html').addClass('bodyscroll');
		});
	$('.back-3 > .text').on('click', function(){
		var popimage = $('.pop-3 .desktop-only img');
		if($(window).width() <= 768){
			popimage.attr('src', popimage.data('mobile'));
		}else{
			popimage.attr('src', popimage.data('desktop'));
		}
		$('.pop-3').show();
		$('body,html').addClass('bodyscroll');
		});
	$('.back-4 > .text').on('click', function(){
		var popimage = $('.pop-4 .desktop-only img');
		if($(window).width() <= 768){
			popimage.attr('src', popimage.data('mobile'));
		}else{
			popimage.attr('src', popimage.data('desktop'));
		}
		$('.pop-4').show();
		$('body,html').addClass('bodyscroll');
	});
	$('.back-5 > .text').on('click', function(){
		var popimage = $('.pop-5 .desktop-only img');
		if($(window).width() <= 768){
			popimage.attr('src', popimage.data('mobile'));
		}else{
			popimage.attr('src', popimage.data('desktop'));
		}
		$('.pop-5').show();
		$('body,html').addClass('bodyscroll');
	});
	$('.back-6 > .text').on('click', function(){
		var popimage = $('.pop-6 .desktop-only img');
		if($(window).width() <= 768){
			popimage.attr('src', popimage.data('mobile'));
		}else{
			popimage.attr('src', popimage.data('desktop'));
		}
		$('.pop-6').show();
		$('body,html').addClass('bodyscroll');
	});
	$('.back-7 > .text').on('click', function(){
		var popimage = $('.pop-7 .desktop-only img');
		if($(window).width() <= 768){
			popimage.attr('src', popimage.data('mobile'));
		}else{
			popimage.attr('src', popimage.data('desktop'));
		}
		$('.pop-7').show();
		$('body,html').addClass('bodyscroll');
	});
	$('.back-8 > .text').on('click', function(){
		var popimage = $('.pop-8 .desktop-only img');
		if($(window).width() <= 768){
			popimage.attr('src', popimage.data('mobile'));
		}else{
			popimage.attr('src', popimage.data('desktop'));
		}
		$('.pop-8').show();
		$('body,html').addClass('bodyscroll');
	});
	$('.back-9 > .text').on('click', function(){
		var popimage = $('.pop-9 .desktop-only img');
		if($(window).width() <= 768){
			popimage.attr('src', popimage.data('mobile'));
		}else{
			popimage.attr('src', popimage.data('desktop'));
		}
		$('.pop-9').show();
		$('body,html').addClass('bodyscroll');
	});
	$('.exed').on('click', function(){
		console.log('saa');
		$('body,html').removeClass('bodyscroll');
		$('.pop-up').hide();
		
	});
	$('body').on('touchmove', function(e){

//		e.preventDefault();
		
		var target = $(e.target);
		console.log(e.target);
		console.log($('.pop-up').scrollTop());
		if($('body').hasClass('bodyscroll') == true){
		if(target.parents('.pop-up').length == 0){
				console.log('outside');
				$(this).on('touchmove', function(e){
					e.preventDefault();
				})
		}
		}
	})
		
		if($('body').hasClass('bodyscroll') == true){
			console.log('saa');
			if(target.parents('.pop-up').length == 0){
				console.log('outside');
				e.preventDefault();
			}else{
				console.log('inside');
			}
		}
	
			
		
	jQuery(document).ready(function(){
		
		var id = 'carousel-';
		var n = 1; 
		var c = 4;  //number of carousel -1
		var flag = '';
		function intervalFunction () {
   // do stuff.
			var combo = '#'+id+n;
//		console.log(combo);
			if(flag !== 'busy'){
				
		$('input').removeAttr('checked');
		$(combo).attr('checked', 'checked');
			}
		console.log(combo);
		if(n <= c){
		n++;
		}else{
		n = 1; 
		}
}
	window.setInterval(function(){ intervalFunction(); }, 4000);
	
		$('.carousel__next, .carousel__prev').on('click','label',function(){
			
        flag = 'busy';
		setTimeout(function(){

			flag = '';

		},7000);
		});
			
		
//	
			});
	
	
	
	$('label[for]').on('click', function (e) {
    var target = window[this.htmlFor];
    target.checked = !target.checked;
    e.preventDefault();
});
	
	$('ul.dropdown-menu li a').click(function(){
		$(this).parents('ul').siblings('button').text($(this).text());
//		console.log('hello');
		$(this).parents('ul').siblings('button').val($(this).attr('data-filter'));
	});
	
	var $grid = $('.isotopezz').isotope({
			// options
			itemSelector: '.isotopezz-item',
			layoutMode: 'fitRows', 
		percentPosition: 'true'
		});
	$(window).load( function(){ 
		console.log('hey');
		$grid.isotope('layout');
	});
//	
	
	var filters = {};
//
//$('ul.dropdown-menu li a').on( 'click', function() {
//  var $this = $(this);
//  // get group key
//	console.log($(this).text());
//  var $buttonGroup = $this.parents('ul').siblings('button');
//  var filterGroup = $buttonGroup.attr('data-filter-group');
//  // set filter for group
//  filters[ filterGroup ] = $this.attr('data-filter');
//  // combine filters
//  var filterValue = concatValues( filters );
//  // set filter for Isotope
//  $grid.isotope({ filter: filterValue });
//});
	
	
	
$('.search img').click(function(){
	
		resetFilter();
		$('.input-filter button').each(function(){
			var $this = $(this);
			var filterGroup = $this.attr('data-filter-group');
			filters[ filterGroup ] = $this.val();
  // combine filters
  var filterValue = concatValues( filters );
  // set filter for Isotope
  $grid.isotope({ filter: filterValue });
		});
	});
	
	
	
	
	
	$(document).ready(function(){
	$('.navbar-toggler').click(function(){
		$(this).find('div').toggleClass('open');
	});
});
	
	
	
	
	
function resetFilter(){
		filters = {};
    $grid.isotope({ filter: '*' }); 
	}	
function concatValues( obj ) {
  var value = '';
  for ( var prop in obj ) {
    value += obj[ prop ];
  }
  return value;
}
	
	$('.auto-filter .rel').click(function(){
		resetFilter();
		var age = '.'+$(this).attr('data-tags');
//		console.log($('#age').siblings('ul li a:contains('+age+')'))
		console.log(age);
		$('ul.dropdown-menu li a:last-of-type').each(function(){
		$(this).parents('ul').siblings('button').text($(this).text());
//		console.log('hello');
		$(this).parents('ul').siblings('button').val($(this).attr('data-filter'));
	});
		var parent = $('.input-filter ul a[data-filter="'+age+'"]').last().parents('ul').siblings('button');
		var link = $('.input-filter ul a[data-filter="'+age+'"]').last().text();
		parent.text(link);
		parent.val(age);
		var filterGroup = "prod";
  // set filter for group
  filters[ filterGroup ] = age;
  // combine filters
  var filterValue = concatValues( filters );
  // set filter for Isotope
  $grid.isotope({ filter: filterValue });
		
		var targetOffset = $(".filter-bar").offset().top - 85;
    $('html, body').animate({
		
        scrollTop:targetOffset
    }, 1000);
//		$('#age').siblings('ul li a:contains('+age+')').click();
		
	});
	
//	$('.search img').click(function(){
//		$('.input-filter button').each(function(){
//			$(this).text($(this).siblings('ul').find('li:last-of-type a').text());
//		});
//		resetFilter();
//	});
	
})(jQuery); // Fully reference jQuery after this point.
